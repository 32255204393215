import cn from "classnames";
import Link from "next/link";
import { ReactNode, useEffect, useRef, useState } from "react";
import { CSSProperties } from "styled-components";

import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";

export const AccordionColumn = ({
  label,
  labelUrl,
  links,
}: {
  label: string;
  labelUrl?: string;
  links: ReactNode;
}) => {
  const [isActive, setIsActive] = useState(false);
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeHandler = () => {
      setIsActive(false);
    };
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <div
      ref={elRef}
      className={cn("column", { active: isActive })}
      style={
        {
          "--element-height": elRef?.current?.scrollHeight + "px",
        } as CSSProperties
      }
    >
      <h2
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        {labelUrl ? <Link href={labelUrl}>{label}</Link> : label}
        <IoIosArrowDown />
      </h2>
      {links}
    </div>
  );
};
