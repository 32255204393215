"use client";

import Link from "next/link";
import { StyledDiv } from "./bigBanner.styles";
import { CSSProperties } from "styled-components";

interface IBigBannerProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonLink?: string;
  style?: CSSProperties;
}

export const BigBanner = ({ title, subtitle, buttonText, buttonLink, style }: IBigBannerProps) => {
  return (
    <StyledDiv style={style}>
      {subtitle && <h4 className="subtitle">{subtitle}</h4>}
      <div className="banner">
        {subtitle &&
          <h4>{subtitle}</h4>
        }
        {title &&
          <h2>{title}</h2>
        }
        {buttonLink && buttonText &&
          <Link
            className="cta"
            href={buttonLink}
          >
            {buttonText}
          </Link>
        }
      </div>
    </StyledDiv>
  );
};

