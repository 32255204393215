import { CSSVars } from '@/styles/styles.config';
import styled from 'styled-components';

export const StyledDiv = styled.div`
	/* background: url(/assets/big-banner-bg.png);
	background-size: 110% 150%;
	background-repeat: no-repeat;
	background-position: center 0%; */
	padding: 0px 20px 100px;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 100px 40px 200px;
	}

	&::after {
		content: '';
		backdrop-filter: blur(12px);
		position: absolute;
		/* left: -100%;
		right: -100%; */
		left: -40px;
		right: -40px;
		bottom: calc(100% + 40px);
		height: 100px;

	}

	.subtitle {
		font-size: ${CSSVars.fontSizeMD};
		font-weight: 300;
		max-width: 900px;
		text-align: center;
		position: relative;
		z-index: 1;
		color: ${CSSVars.white};
		display: none;
		letter-spacing: 5.4px;
		transform: translateY(120px);

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			display: block;
			font-size: ${CSSVars.fontSizeLG};
		}
	}

	.banner {
		border-radius: 8px;
		border: 1px solid #fff;
		max-width: 800px;
		padding: 30px;
		position: relative;

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			padding: 140px 60px 50px;
		}

		&::before {
			content: '';
			position: absolute;
			pointer-events: none;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(
				180deg,
				rgba(76, 79, 255, 0) 19.56%,
				rgba(76, 79, 255, 0) 37.46%,
				#fff 113.78%
			);
			opacity: 0;
			transition: opacity 0.3s linear;
			border-radius: inherit;
		}

		&:hover {
			&::before {
				opacity: 0.8;
			}

			.cta {
				background: ${CSSVars.white};
				color: ${CSSVars.black};
				z-index: 1;
			}
		}

		h4 {
			margin: 0 auto;
			font-size: ${CSSVars.fontSizeMD};
			font-weight: 300;
			max-width: 900px;
			text-align: center;
			display: block;

			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				display: none;
				font-size: ${CSSVars.fontSizeLG};
			}
		}

		h2 {
			margin: 10px auto 30px;
			text-align: center;
			font-size: ${CSSVars.fontSize2XL};
			font-style: normal;
			font-weight: 300;
			max-width: 700px;

			background: linear-gradient(277deg, #fff 48.61%, #808aad 109.97%);
			background-clip: text;
			color: transparent;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize3XL};
			}

			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				font-size: ${CSSVars.fontSize4XL};
			}
		}

		.cta {
			z-index: 10;
			display: flex;
			width: fit-content;
			margin: 0 auto;
			transition: 0.3s;
			color: ${CSSVars.white};
			font-size: ${CSSVars.fontSize2XL};
			font-weight: 300;
			padding: 12px 30px;
			border-radius: 8px;
			border: 1px solid ${CSSVars.white};

			@media screen and (max-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSizeLG};
				padding: 8px 25px;
			}
		}
	}
`;
