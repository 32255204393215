import styled from "styled-components";
import { CSSVars } from "../../styles/styles.config";

export const StyledFooter = styled.footer`
  padding: 100px 40px 0px;
  position: relative;

  &::before {
    content: "";

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    opacity: 0.7;

    background: url(/assets/footer-background.webp);
    background-repeat: no-repeat;
    background-size: 100% 400px;
  }

  &.big-banner {
    &::before {
      background-size: 100% 1400px;
    }
  }

  &.transparent {
    &::before {
      background: transparent;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: 0 auto;
  }

  &.dark {
    a,
    h2,
    p {
      color: ${CSSVars.white};
    }
  }

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 300;
    font-size: ${CSSVars.fontSizeSM};
    color: ${CSSVars.gray900};
    max-width: 260px;
    text-align: center;
    transition: 0.2s;
    margin: 9px 0;
    min-width: 48px; // For Lighthouse

    &:hover {
      opacity: 0.7;
    }

    .icon {
      padding-bottom: 1px;
    }

    @media screen and (min-width: ${CSSVars.breakpointSM}) {
      text-align: left;
    }

    .icon {
      font-size: 16px;
      color: inherit;
    }
  }

  .subscription-container {
    width: 80%;

    p {
      width: max-content;
      margin: 30px 0 15px;
      font-size: ${CSSVars.fontSizeLG};
    }

    .input-container {
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      position: relative;

      input {
        background: none;
        border: none;
        z-index: 1;

        padding: 5px 0 5px 12px;
        margin: 0;
        width: 80%;

        font-size: ${CSSVars.fontSizeSM};
        line-height: 21px;

        color: white;

        &::placeholder {
          color: ${CSSVars.gray300};
        }
      }

      button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: white;
        border-radius: 8px;
        padding: 0px 8px;
        cursor: pointer;
        overflow: hidden;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          height: 100%;
          width: 100%;
          border-radius: inherit;
          background: linear-gradient(
              90deg,
              rgba(76, 79, 255, 0.4),
              rgba(255, 255, 255, 1.1)
            ),
            linear-gradient(45deg, white, transparent);
          transition: 0.2s;

          z-index: 1;
        }

        &:hover {
          &::before {
            transform: translateX(100%);
          }
        }

        > svg {
          z-index: 2;
        }
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 1px;
        background: white;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }

  .top {
    padding-bottom: 30px;
    display: grid;

    @media screen and (min-width: ${CSSVars.breakpointSM}) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (min-width: ${CSSVars.breakpointMD}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: ${CSSVars.breakpointLG}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: ${CSSVars.breakpointXL}) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (min-width: ${CSSVars.breakpoint2XL}) {
      grid-template-columns: repeat(6, 1fr);
    }

    .column {
      display: flex;
      flex-direction: column;
      color: ${CSSVars.gray900};
      align-items: center;
      width: max-content;
      padding: 0 2% 25%;

      &.blog-posts {
        /* Blog footer column, cut number of lines */
        a:not(:last-child) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      h2 svg {
        display: none;
      }

      @media screen and (min-width: ${CSSVars.breakpointSM}) {
        align-items: flex-start;
      }

      @media screen and (max-width: ${CSSVars.breakpointMD}) {
        align-items: baseline;
        width: 100%;
        padding: 0;

        &:first-child {
          margin-bottom: 10px;
          * {
            margin-left: auto;
            margin-right: auto;

            button,
            input {
              margin: 0;
            }
          }
        }

        &:not(:first-child) {
          overflow: hidden;
          max-height: 65px;
          transition: all 0.6s;

          h2 svg {
            display: block;
            transition: 0.3s;
            rotate: 0deg;
          }

          &.active {
            max-height: var(--element-height, 400px);

            h2 svg {
              rotate: 180deg;
            }
          }

          h2 {
            text-align: left;
            width: 100%;
            padding: 20px 0;
            border-bottom: 1px solid #5f5f5f;

            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      h2 {
        font-weight: 500;
        font-size: ${CSSVars.fontSizeMD};
        margin-bottom: 10px;

        &:not(:first-child) {
          margin: 30px 0 0px;

          @media screen and (max-width: ${CSSVars.breakpointMD}) {
            margin-right: auto;
            margin-left: auto;
          }
        }

        a {
          font-weight: inherit;
          font-size: inherit;
          margin: 0;
          min-width: auto;
          cursor: "pointer";
        }
      }

      .social-links {
        display: flex;
        gap: 12px;
        padding-right: 80px;

        @media screen and (max-width: ${CSSVars.breakpoint2XL}) {
          padding-right: 30px;
        }
        
        @media screen and (max-width: ${CSSVars.breakpointMD}) {
          padding-right: 40px;
        }
        @media screen and (max-width: ${CSSVars.breakpointSM}) {
          padding-right: 0px;
        }

        a {
          min-width: max-content;
          padding: 10px;
          border: 1px solid #5f5f5f;
          border-radius: 6px;

          svg {
            width: 19px;
            height: 20px;
          }
        }
      }

      .bold-link {
        font-weight: 500;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
    margin-top: -30px;

    flex-direction: column-reverse;
    color: ${CSSVars.gray600};

    @media screen and (min-width: ${CSSVars.breakpointLG}) {
      flex-direction: row;
    }

    small {
      font-weight: 300;
      font-size: ${CSSVars.fontSizeSM};
      text-align: center;
    }

    .bottom-links {
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      a {
        color: inherit;
      }

      @media screen and (min-width: ${CSSVars.breakpointLG}) {
        gap: 50px;
        flex-direction: row;
      }
    }
  }

  &.homepage-layout {
    &::before {
      display: none;
    }
    padding: 300px 100px 0;

    @media screen and (max-width: ${CSSVars.breakpointMD}) {
      padding: 150px 50px 0;
    }
    @media screen and (max-width: ${CSSVars.breakpointLG}) {
      padding: 200px 50px 0;
    }

    .top {
      border-top: 1px solid var(--white, #fff);
      padding-top: 50px;
    }
  }
`;
